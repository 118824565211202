<template>
  <!-- 充值流水 -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <!-- 统计 -->
    <div class="count-box">
      <div class="item">
        <div class="detail">
          <div class="left">
            <i class="el-icon-s-data"></i>
          </div>
          <div class="right">
            <span class="value">{{ stat.recharge_amount || 0 }}</span>
            <span class="label">充值总金额</span>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="detail">
          <div class="left orange">
            <i class="el-icon-money"></i>
          </div>
          <div class="right">
            <span class="value">{{ stat.withdraw_amount || 0 }}</span>
            <span class="label">退款总金额</span>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="detail">
          <div class="left blue">
            <i class="el-icon-coin"></i>
          </div>
          <div class="right">
            <span class="value">{{ stat.actual_amount || 0 }}</span>
            <span class="label">实际总金额</span>
          </div>
        </div>
      </div>
    </div>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="订单号">
          <el-input v-model="searchData.order_no" placeholder="请输入" clearable size="small"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="searchData.mobile" placeholder="请输入" clearable size="small"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="searchData.pay_status" placeholder="请选择" clearable size="small">
            <el-option label="待支付" :value="0"> </el-option>
            <el-option label="支付成功" :value="2"> </el-option>
            <el-option label="支付失败" :value="3"> </el-option>
            <el-option label="退款成功" :value="4"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="支付时间">
          <el-date-picker
            style="width: 250px"
            v-model="searchData.timeSlot"
            size="small"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getList()">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-divider></el-divider>

    <tp-table :isNeedSerialNumber="true" :tableData="list" :columns="columns" :totalNum="total" :current-page.sync="currentPage" :pageSize.sync="pageSize" />
  </div>
</template>

<script>
import { getListAPI, delAPI, getStatAPI } from './api'
import { dict_order_status } from '@/enum/dict.js'
import { authBtnMixin } from '@/mixins/authBtnMixin'
const columns = [
  {
    label: 'ID',
    prop: 'user',
    minWidth: '80',
    customRender(h, row) {
      return <div>{row['id']}</div>
    }
  },
  {
    label: '用户信息',
    prop: 'user',
    minWidth: '180',
    customRender(h, row) {
      return (
        <div style="padding:5px 0">
          <p>昵称：{row['user']?.['nickname']}</p>
          <p>手机号：{row['user']?.['mobile']}</p>
        </div>
      )
    }
  },
  {
    label: '状态',
    prop: 'pay_status_dsc',
    minWidth: '100',
    customRender(h, row) {
      return (
        <div>
          <el-tag v-show={row['pay_status'] == 0} size="small" type="warning">
            {row['pay_status_dsc']}
          </el-tag>
          <el-tag v-show={row['pay_status'] == 1} size="small" type="info">
            {row['pay_status_dsc']}
          </el-tag>
          <el-tag v-show={row['pay_status'] == 2} size="small" type="primary">
            {row['pay_status_dsc']}
          </el-tag>
          <el-tag v-show={row['pay_status'] == 3} size="small" type="danger">
            {row['pay_status_dsc']}
          </el-tag>
          <el-tag v-show={row['pay_status'] == 4} size="small" type="success">
            {row['pay_status_dsc']}
          </el-tag>
        </div>
      )
    }
  },
  {
    label: '充值金额',
    prop: 'recharge_amount',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['recharge_amount']}</p>
    }
  },
  {
    label: '订单金额',
    prop: 'order_amount',
    minWidth: '150',
    customRender(h, row) {
      return (
        <div>
          <p>订单金额：{row['order_amount']}</p>
        </div>
      )
    }
  },

  {
    label: '充值时间',
    prop: 'created_at',
    minWidth: '150',
    customRender(h, row) {
      return <p>{row['created_at']}</p>
    }
  },
  {
    label: '支付时间',
    prop: 'pay_time',
    minWidth: '150',
    customRender(h, row) {
      return <p>{row['pay_time']}</p>
    }
  },

  {
    label: '订单号',
    prop: 'order_no',
    minWidth: '160',
    customRender(h, row) {
      return <p>{row['order_no']}</p>
    }
  },
  {
    label: '流水号',
    prop: 'serial_no',
    minWidth: '160',
    customRender(h, row) {
      return <p>{row['serial_no']}</p>
    }
  },

  {
    label: '操作',
    minWidth: '130',
    fixed: 'right',
    customRender(h, row) {
      return (
        <div>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.recharge_order_detail)}
            underline={false}
            type="primary"
            onClick={() => this.$router.push({ name: 'RechargeOrderDetail', params: { id: row['id'] } })}
          >
            详情
          </el-link>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.recharge_order_del)}
            style="margin-left:10px"
            underline={false}
            type="primary"
            onClick={() => this.handleRemove(row)}
          >
            删除
          </el-link>
        </div>
      )
    }
  }
]
export default {
  name: 'List',
  mixins: [authBtnMixin],
  data() {
    return {
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: [],
      searchData: {
        order_no: '',
        pay_status: '',
        timeSlot: [],
        mobile: ''
      },
      dict_order_status,
      stat: {}
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {
    this.getList()
  },
  watch: {
    currentPage() {
      this.getList()
    },
    pageSize() {
      this.getList()
    }
  },
  methods: {
    async getStat() {
      const { order_no, pay_status, timeSlot, mobile } = this.searchData
      let params = { order_no, pay_status, mobile }
      if (timeSlot && timeSlot.length > 0) {
        params.leftCreateDate = timeSlot[0]
        params.rightCreateDate = timeSlot[1]
      }
      this.stat = await getStatAPI(params)
    },
    async getList() {
      const { order_no, pay_status, timeSlot, mobile } = this.searchData
      let params = { page: this.currentPage, page_size: this.pageSize, order_no, pay_status, mobile }
      if (timeSlot && timeSlot.length > 0) {
        params.leftCreateDate = timeSlot[0]
        params.rightCreateDate = timeSlot[1]
      }
      const res = await getListAPI(params)
      this.list = res.data || []
      this.total = res.total || 0
      this.getStat()
    },
    handleRemove({ id }) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAPI(id).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .header-search {
    .el-form {
      .el-form-item {
        margin-right: 15px;
        margin-bottom: 10px;
        .el-input {
          width: 250px;
        }
        .el-date-editor {
          .el-range-separator {
            padding: 0;
          }
          .el-range-input {
            width: 35%;
          }
        }
      }
      // .el-select .el-input {
      //   width: 100px;
      // }
      // .input-with-select {
      //   vertical-align: middle;
      // }
      // .input-with-select .el-input-group__prepend {
      //   background-color: #fff;
      // }
    }
  }
  .count-box {
    display: grid;
    grid-template-columns: repeat(3, minmax(250px, 1fr));

    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
    .item {
      display: flex;
      justify-content: center;
      padding: 20px;
      box-shadow: 0 0px 2px 0 rgba(114, 114, 114, 0.1);
      border-radius: 6px;
      transition: all 0.2s linear;
      // background: #f0f2f5;
      &:hover {
        box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.1);
        // background: #f0f2f5;
      }
      .detail {
        display: flex;
        align-items: center;
        .left {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          background-color: #409eff;
          margin-right: 20px;
          color: #fff;
          font-size: 28px;
        }
        .right {
          display: flex;
          flex-direction: column;
          .label {
          }
          .value {
            font-weight: 700;
            font-size: 22px;
          }
        }
        .orange {
          background: #feab28;
        }
        .pink {
          background: #fe86c3;
        }
        .purple {
          background: #b480ec;
        }
        .green {
          background: #6dd32f;
        }
        .blue {
          background: #5882bb;
        }
      }
    }
  }
  .count-stat {
    display: flex;
    align-items: center;
    padding: 7px 0;
    background: #ecf5ff;
    margin-bottom: 10px;
    // border: 1px solid #409eff;
    .item {
      display: flex;
      align-items: center;
      margin-right: 20px;
      .value {
        color: #f56c6c;
      }
    }
  }
  .el-divider--horizontal {
    margin: 0 0 10px;
  }
  .header-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .tp-table {
    .el-table__fixed-right {
      height: 100% !important;
    }
  }
  .el-dialog__body {
    padding-top: 0;
  }
}
</style>
